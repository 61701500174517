import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Scalars, UserNode, UserType } from '@app/generated/graphql';
import { TeamMembersService } from '@app/shared/services/team-members/team-members.service';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { LocalStorageService } from '@app/ui-v2/services/local-storage.service';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, repeat } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'team-members-v2',
  templateUrl: './team-members-v2.component.html',
  styleUrls: ['./team-members-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMembersV2Component implements OnInit {
  UserType = UserType;

  teamMembers$: BehaviorSubject<Set<UserNode>> = new BehaviorSubject(new Set());

  @Input() ticketingAgents: boolean;
  @Input() storeKey: string | null = 'team_members';
  @Input() selectedUsersIds?: string[] | string = [];
  @Output() teamMembers = new EventEmitter<Set<UserNode>>();
  @Output() selectedTeamMembers = new EventEmitter<string[]>();

  constructor(
    private localStorageService: LocalStorageService,
    private cognitoAuthService: CognitoAuthService,
    private teamMembersService: TeamMembersService
  ) {}

  ngOnInit() {
    this.getTeamMembers()
      .pipe(UntilDestroyed(this))
      .subscribe((users: UserNode[]) => {
        if (users) {
          this.teamMembers.emit(new Set(users));
          this.teamMembers$.next(new Set(users));

          if (!this.selectedUsersIds?.length && this.storeKey) {
            const teamMembersIds = new Set(users.map((member) => member.id));
            const storedTeamMembersIds = this.getStoredTeamMembersIds();

            if (storedTeamMembersIds?.size) {
              for (const storedTeamMember of storedTeamMembersIds) {
                if (!teamMembersIds.has(storedTeamMember) && storedTeamMember !== '') {
                  storedTeamMembersIds.delete(storedTeamMember);
                }
              }

              this.selectedUsersIds = Array.from(storedTeamMembersIds);
              this.selectedTeamMembers.emit(this.selectedUsersIds);
            } else {
              this.selectedTeamMembers.emit([]);
            }
          } else {
            this.storeTeamMembersIds();
          }
        }
      });
  }

  getTeamMembers(): Observable<UserNode[]> {
    if (this.ticketingAgents) {
      return this.teamMembersService.getTicketingAgents();
    }

    if (this.cognitoAuthService.isSalesDirector) {
      return this.teamMembersService.getSalesAgentsAndTeamLeads();
    }

    return this.teamMembersService.getTeamMembers(this.cognitoAuthService.user.id).pipe(
      repeat({ delay: TEAM_MEMBERS_REFETCH_DELAY }),
      map((users) => {
        return [this.cognitoAuthService.user, ...users].filter(
          (user) => user.type !== UserType.Searcher
        ) as UserNode[];
      })
    );
  }

  getStoredTeamMembersIds() {
    const storedTeamMembers: string[] =
      this.localStorageService.getItem(this.storeKey as string) || [];
    return new Set(storedTeamMembers);
  }

  storeTeamMembersIds() {
    if (this.storeKey) {
      const teamMembers = Array.from(this.selectedUsersIds || []);
      this.localStorageService.setItem(this.storeKey, teamMembers);
    }
  }

  selectMember(memberId: string): void {
    const selectedUsersIdsArray = this.selectedUsersIds as string[];

    if (selectedUsersIdsArray?.includes(memberId)) {
      const indexToRemove = selectedUsersIdsArray.findIndex((id) => id === memberId);
      selectedUsersIdsArray.splice(indexToRemove, 1);
    } else {
      this.selectedUsersIds = selectedUsersIdsArray || [];
      selectedUsersIdsArray.push(memberId);
    }

    this.selectedTeamMembers.emit(selectedUsersIdsArray);
    this.storeTeamMembersIds();
  }

  isSelected(id: Scalars['ID']): boolean {
    return Boolean(this.selectedUsersIds?.includes(id));
  }
}

export const TEAM_MEMBERS_REFETCH_DELAY = 30000;
