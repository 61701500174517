import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'right-pane',
  templateUrl: './right-pane.component.html',
  styleUrls: ['./right-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightPaneComponent {
  @Input() showedPane = true;
  @Output() toggleRightPane = new EventEmitter();
}
