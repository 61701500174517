import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Scalars, UserNode, UserType } from '@app/generated/graphql';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { LocalStorageService } from '@app/ui-v2/services/local-storage.service';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { map, repeat } from 'rxjs/operators';
import { TEAM_MEMBERS_REFETCH_DELAY } from '../team-members-v2/team-members-v2.component';
import { TeamMembersService } from '@app/shared/services/team-members/team-members.service';

@UntilDestroy()
@Component({
  selector: 'team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMembersComponent implements OnInit {
  UserType = UserType;

  teamMembers$: BehaviorSubject<Set<UserNode>> = new BehaviorSubject(new Set());
  resetSelectionSubscription: Subscription;
  selectedUsersIds: Set<string> = new Set();

  @Input() ticketingAgents: boolean;
  @Output() teamMembers = new EventEmitter<Set<UserNode>>();

  constructor(
    private localStorageService: LocalStorageService,
    private cognitoAuthService: CognitoAuthService,
    private teamMembersService: TeamMembersService
  ) {}

  ngOnInit() {
    this.getTeamMembers()
      .pipe(UntilDestroyed(this))
      .subscribe((users: UserNode[]) => {
        if (users) {
          this.teamMembers.emit(new Set(users));
          this.teamMembers$.next(new Set(users));

          const teamMembersIds = new Set(users.map((member) => member.id));
          const storedTeamMembersIds = this.getStoredTeamMembersIds();

          if (storedTeamMembersIds?.size) {
            this.selectedUsersIds = storedTeamMembersIds;

            for (const storedTeamMember of storedTeamMembersIds) {
              if (!teamMembersIds.has(storedTeamMember)) {
                storedTeamMembersIds.delete(storedTeamMember);
              }
            }

            this.teamMembersService.replaceMembers(storedTeamMembersIds);
          } else {
            this.teamMembersService.replaceMembers(teamMembersIds);
          }
        }
      });
  }

  getTeamMembers(): Observable<UserNode[]> {
    if (this.ticketingAgents) {
      return this.teamMembersService.getTicketingAgents();
    }

    if (this.cognitoAuthService.isSalesDirector) {
      return this.teamMembersService.getSalesAgentsAndTeamLeads();
    }

    return this.teamMembersService.getTeamMembers(this.cognitoAuthService.user.id).pipe(
      repeat({ delay: TEAM_MEMBERS_REFETCH_DELAY }),
      map((users) => {
        return [this.cognitoAuthService.user, ...users].filter(
          (user) => user.type !== UserType.Searcher
        ) as UserNode[];
      })
    );
  }

  getStoredTeamMembersIds() {
    const storedTeamMembers: string[] = this.localStorageService.getItem('team_members') || [];
    return new Set(storedTeamMembers);
  }

  storeTeamMembersIds() {
    const teamMembers = Array.from(this.selectedUsersIds);
    this.localStorageService.setItem('team_members', teamMembers);
  }

  selectMember(id: string): void {
    if (this.selectedUsersIds.has(id)) {
      this.selectedUsersIds.delete(id);
    } else {
      this.selectedUsersIds.add(id);
    }

    const selectedMembers =
      this.selectedUsersIds.size === 0
        ? new Set([...this.teamMembers$.value].map((user: UserNode) => user.id))
        : this.selectedUsersIds;

    this.teamMembersService.replaceMembers(selectedMembers);
    this.storeTeamMembersIds();
  }

  isSelected(id: Scalars['ID']): boolean {
    return this.selectedUsersIds.has(id);
  }
}
