import { Pipe, PipeTransform } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Pipe({
  name: 'extractTypeAheadMatch',
})
export class ExtractTypeAheadMatchPipe implements PipeTransform {
  transform(val: TypeaheadMatch[]): any[] {
    if (!val) {
      return [];
    }

    return val.map((match) => ({ ...match.item, originalMatch: match }));
  }
}
