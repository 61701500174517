import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';

@Component({
  selector: 'finance-card',
  templateUrl: './finance-card.component.html',
  styleUrls: ['./finance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCardComponent implements OnInit, OnChanges {
  formControl = new UntypedFormControl(0);
  value: number;

  @HostBinding('class.editable') @Input() editable = false;
  @HostBinding('class.locked') @Input() locked? = false;
  @Input() title: string;
  @Input() item: any;
  @Input() dangerously = false;
  @Input() asNumber = false;
  @Input() withCents = false;
  @Output() changeAmount: EventEmitter<number> = new EventEmitter();

  @ViewChild('editableCmp') editableCmp: EditableComponent;

  ngOnInit(): void {
    this.formControl.setValue(this.item?.amount);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newValue = changes.item?.currentValue?.amount;

    if (typeof newValue === 'number' && newValue !== this.formControl.value) {
      this.formControl.setValue(newValue);
    }
  }

  update(): void {
    this.changeAmount.emit((this.formControl.value as number) || 0);
  }

  isNumber(fees: number | undefined): boolean {
    return typeof fees === 'number';
  }
}
