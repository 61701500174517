import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyForm } from '@ngx-formly/core';

@Component({
  selector: 'radar-formly-form',
  templateUrl: './radar-formly-form.component.html',
  styleUrls: ['./radar-formly-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarFormlyFormComponent extends FormlyForm {}
