import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'booleanToYesNo',
})
export class BooleanToYesNoPipe implements PipeTransform {
  transform(value?: boolean): string {
    if (isNil(value)) {
      return '-';
    }

    return value ? 'Yes' : 'No';
  }
}
