import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'inner-layout',
  templateUrl: './inner-layout.component.html',
  styleUrls: ['./inner-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerLayoutComponent implements OnInit {
  @HostBinding('class.has-left') @Input() hasLeftPane = true;
  @HostBinding('class.has-right') @Input() hasRightPane = false;
  @HostBinding('class.no-top-padding') @Input() noTopPadding = false;

  @HostBinding('class.collapsed-left') get isShowedLeftPane() {
    return !this.panels.left.showed;
  }

  @HostBinding('class.collapsed-right') get isShowedRightPane() {
    return !this.panels.right.showed;
  }

  resiziblePane = '';

  panels: Panels = {
    left: {
      minWidth: 120,
      maxWidth: 440,
      defaultWidth: 200,
      currentWidth: 0,
      showed: false,
    },
    right: {
      minWidth: 260,
      maxWidth: 660,
      defaultWidth: 288,
      currentWidth: 0,
      showed: false,
    },
  };

  ngOnInit(): void {
    const showedLeftPane = getItem(PaneKey.left);
    this.panels.left.showed = showedLeftPane ? parse(showedLeftPane) : this.hasLeftPane;

    const showedRightPane = getItem(PaneKey.right);
    this.panels.right.showed = showedRightPane ? parse(showedRightPane) : this.hasRightPane;

    const widthLeftPane = getItem(PaneKey.leftWidth);
    this.panels.left.currentWidth = widthLeftPane
      ? parse(widthLeftPane)
      : this.panels.left.defaultWidth;

    const widthRightPane = getItem(PaneKey.rightWidth);
    this.panels.right.currentWidth = widthRightPane
      ? parse(widthRightPane)
      : this.panels.right.defaultWidth;
  }

  togglePane(key: PaneType): void {
    this.panels[key].showed = !this.panels[key].showed;
    setItem(PaneKey[key], this.panels[key].showed);
  }

  onMousedown(pane: PaneType): void {
    this.resiziblePane = pane;
  }

  onMouseup(): void {
    this.resiziblePane = '';

    if (this.panels.left.currentWidth < this.panels.left.minWidth) {
      this.togglePane('left');
      this.panels.left.currentWidth = this.panels.left.defaultWidth;
    }
    setItem(PaneKey.leftWidth, this.panels.left.currentWidth);

    if (this.panels.right.currentWidth < this.panels.right.minWidth) {
      this.togglePane('right');
      this.panels.right.currentWidth = this.panels.right.defaultWidth;
    }
    setItem(PaneKey.rightWidth, this.panels.right.currentWidth);
  }

  onMousemove(e: MouseEvent): void {
    if (this.resiziblePane === 'left' && e.clientX <= this.panels.left.maxWidth) {
      this.panels.left.currentWidth = e.clientX;
    }

    const width = window.innerWidth - e.clientX;
    if (this.resiziblePane === 'right' && width <= this.panels.right.maxWidth) {
      this.panels.right.currentWidth = width;
    }
  }
}

type Pane = {
  minWidth: number;
  maxWidth: number;
  defaultWidth: number;
  currentWidth: number;
  showed: boolean;
};

enum PaneKey {
  left = 'showedLeftPane',
  right = 'showedRightPane',
  leftWidth = 'widthLeftPane',
  rightWidth = 'widthRightPane',
}

type PaneType = 'left' | 'right';

type Panels = { [key in PaneType]: Pane };

const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

const setItem = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log('localStorage.setItem error: ', e);
  }
};

const parse = (json: string) => JSON.parse(json);
