import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RADAR_TABLE_INITIAL_PAGINATION_PAGE } from '../radar-table.constants';
import { RadarTablePagination } from '../radar-table.types';

@Component({
  selector: 'radar-table-pagination',
  templateUrl: './radar-table-pagination.component.html',
  styleUrls: ['./radar-table-pagination.component.scss'],
})
export class RadarTablePaginationComponent {
  @Input() pagination: RadarTablePagination;

  @Output() paginate: EventEmitter<number> = new EventEmitter();

  Math = Math;
  paginationFirstPage = RADAR_TABLE_INITIAL_PAGINATION_PAGE;

  onPaginate(offset: number): void {
    this.paginate.emit(offset);
  }
}
