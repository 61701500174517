import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'radar-table-search',
  templateUrl: './radar-table-search.component.html',
  styleUrls: ['./radar-table-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableSearchComponent {
  @Input() searchPlaceholder: string;
  @Input() searchValue: string;

  @Output() search: EventEmitter<string> = new EventEmitter();

  onSearch(event: Event): void {
    const newSearchValue = (event.target as HTMLInputElement).value;
    this.search.emit(newSearchValue);
  }

  onClear(): void {
    this.search.emit('');
  }
}
