<ng-container *ngFor="let teamMember of teamMembers$ | async; let last = last">
  <div class="d-flex position-relative">
    <user-picture-v2
      (click)="selectMember(teamMember.id)"
      [ngClass]="{ 'v2-mr-1': !last, selected: isSelected(teamMember.id) }"
      [picture]="teamMember.picture"
      [label]="teamMember.fullName"
      [tooltip]="tooltipTemplate"
      [adaptivePosition]="false"
      container="body"
      placement="bottom"
      class="cursor-pointer member"
      size="small"
    ></user-picture-v2>

    <ng-container *showForRole="UserType.SalesTeamLead">
      <div *ngIf="teamMember.callProfile?.isAvailable " class="position-absolute status"></div>
    </ng-container>
  </div>

  <ng-template #tooltipTemplate>
    <typography look="small">{{ teamMember.fullName }}</typography>
  </ng-template>
</ng-container>
