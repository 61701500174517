import { Pipe, PipeTransform } from '@angular/core';
import { RdrDatePipe } from '@ui/pipes/rdr-date.pipe';
import { RdrCurrencyPipe } from '@ui/pipes/rdr-currency.pipe';

@Pipe({
  name: 'formlyFieldFormattedValue',
})
export class FormlyFieldFormattedValuePipe implements PipeTransform {
  constructor(private rdrDate: RdrDatePipe, private rdrCurrency: RdrCurrencyPipe) {}

  transform(val: any, type?: string): string | null {
    if (!val) {
      return '';
    }

    switch (type) {
      case 'date':
        return this.rdrDate.transform(val as Date);
      case 'currency':
        return this.rdrCurrency.transform(val as number, 'default');

      default:
        return val;
    }
  }
}
