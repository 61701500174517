<div class="toolbar d-flex flex-column">
  <ng-container *ngIf="withTeamMembersSelector">
    <div *showForRole="roles.SalesTeamLead" class="v2-mb-4">
      <team-members-v2
        storeKey="team_members"
        [selectedUsersIds]="tableState.filters.selectedUserIds || []"
        (selectedTeamMembers)="teamMembersSelected.emit($event)"
        (teamMembers)="allTeamMembers.emit($event)"
      >
      </team-members-v2>
    </div>
  </ng-container>
  <div *ngIf="(rows | async) || cachedRows" class="d-flex align-items-center h-34">
    <radar-table-search
      *ngIf="searchPlaceholder"
      (search)="onSearch($event)"
      [searchValue]="tableState.search || ''"
      [searchPlaceholder]="searchPlaceholder"
    ></radar-table-search>
    <ng-content></ng-content>
  </div>
</div>

<ng-container *ngIf="(rows | async) || cachedRows as rows; else loading">
  <ng-container *ngIf="rows?.length; else empty">
    <radar-table
      class="with-filter"
      [class.top-headers-90]="withTeamMembersSelector && isSalesLead"
      [readonly]="true"
      [stickHeaders]="true"
      [skin]="'card-rows'"
      (selection)="onSelect($event)"
      (sorting)="onSort($event)"
      (rowClick)="onRowClick($event)"
      [rowLink]="rowLink"
      [columns]="columns"
      [rows]="rows"
      [sort]="tableState.sort"
      [selected]="tableState.selected"
      [columnsFixed]="columnsFixed"
    ></radar-table>
  </ng-container>

  <div class="footer" data-id="table-footer">
    <span
      >Total Count:
      <span [attr.data-id]="'total-count'">{{ tableState.pagination.totalCount }}</span></span
    >
    <radar-table-pagination
      *ngIf="tableState.pagination.totalCount > tableState.pagination.limit"
      (paginate)="onPaginate($event)"
      [pagination]="tableState.pagination"
      class="pagination"
    ></radar-table-pagination>
  </div>
</ng-container>

<ng-template #loading>
  <div class="d-flex flex-column v2-mt-4">
    <skeleton-loader-small class="w-100 v2-mb-8" [height]="32" [width]="92"></skeleton-loader-small>
    <card class="v2-mb-1" *ngFor="let x of [].constructor(5)">
      <skeleton-loader-small class="w-100" [height]="16" [width]="92"></skeleton-loader-small>
    </card>
  </div>
</ng-template>

<ng-template #empty>
  <block-empty-msg class="v2-mt-4">No exact matches found</block-empty-msg>
</ng-template>
