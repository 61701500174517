import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CallPopupService,
  CallPopupStatus,
  CustomParameters,
} from '@calls/services/call-popup/call-popup.service';

@Component({
  selector: 'call-popup',
  templateUrl: './call-popup.component.html',
  styleUrls: ['./call-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallPopupComponent {
  CallPopupStatus = CallPopupStatus;

  @Input() customParameters: CustomParameters;

  constructor(private callPopupService: CallPopupService) {}

  acceptCall() {
    this.callPopupService.accept();
  }

  disconnectCall() {
    if (this.customParameters.status === CallPopupStatus.Accepted) {
      this.callPopupService.disconnect();
    } else {
      this.callPopupService.reject();
      this.callPopupService.close();
    }
  }

  closePopup() {
    this.callPopupService.close();
  }

  openLead() {
    window.open(`/leads/${this.customParameters.leadId}`, '_blank');
  }
}
