import { FormlyFieldConfig } from '@ngx-formly/core';
import lodash from 'lodash';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PLACEHOLDER } from '@shared/constants';
import {
  RdrFormlyFieldConfig,
  RdrFormlyFieldConfig2,
} from '@app/formly/types/basefield/basefield.component';
import { RdrTypeaheadFormlyFieldConfig } from '../types/typeahead/typeahead.component';
import { DropdownItem } from '@ui/components/dropdown.model';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { CountV2FormlyFieldConfig } from '../types/v2/count-v2/count-v2.component';
import { RdrDateFormlyFieldConfig } from '../types/v3/date-v3/date-v3.component';

const globalFieldDefaults: FormlyFieldConfig = {
  templateOptions: {
    placeholder: PLACEHOLDER,
    showCopyToClipboard: true,
  },
};

export const CURRENCY = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'currency',
    wrappers: ['form-field'],
    modelOptions: {
      updateOn: 'blur',
    },
  };

  return buildField(defaults, args);
};

export const TEXT_INPUT = (args: RdrFormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'text-input',
    wrappers: ['form-field'],
    modelOptions: {
      updateOn: 'blur',
    },
  };

  return buildField(defaults, args);
};

export const PHONE = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'phone',
    wrappers: ['form-field'],
    templateOptions: {
      placeholder: '+0 000 000-00-00',
      autofocus: true,
    },
    modelOptions: {
      updateOn: 'blur',
    },
    validators: {
      validation: ['phone'],
    },
  };

  return buildField(defaults, args);
};

export const PHONE_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'phone-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      placeholder: '+1 978 857 5722',
    },
    validators: {
      validation: ['phone'],
    },
  };

  return buildField(defaults, args);
};

export const NUMERIC = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'numeric',
    wrappers: ['form-field'],
    modelOptions: {
      updateOn: 'blur',
    },
  };

  return buildField(defaults, args);
};

export const DATE = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'date',
    wrappers: ['form-field'],
  };
  return buildField(defaults, args);
};

export const DATERANGE = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'date-range',
    wrappers: ['form-field'],
  };
  return buildField(defaults, args);
};

export const SELECT = (args: FormlyFieldConfig, optionsService: Observable<any>) => {
  const defaults: FormlyFieldConfig = {
    type: 'select',
    wrappers: ['form-field'],
    templateOptions: {
      showCopyToClipboard: false,
    },
    hooks: {
      onInit: (field?: FormlyFieldConfig) => {
        if (field && field.templateOptions) {
          field.templateOptions.loaded = false;
          field.templateOptions.options = optionsService.pipe(
            finalize(() => {
              if (field.templateOptions) {
                field.templateOptions.loaded = true;
              }
            })
          );
        }
      },
    },
  };
  return buildField(defaults, args);
};

export const TYPEAHEAD = (args: RdrTypeaheadFormlyFieldConfig, optionsService: Observable<any>) => {
  const defaults: FormlyFieldConfig = {
    type: 'typeahead',
    wrappers: ['form-field'],
    templateOptions: {
      clearable: true,
      showCopyToClipboard: false,
      emitFullObject: false,
    },
    hooks: {
      onInit: (field?: FormlyFieldConfig) => {
        if (field && field.templateOptions) {
          field.templateOptions.loaded = false;
          field.templateOptions.options = optionsService.pipe(
            finalize(() => {
              if (field.templateOptions) {
                field.templateOptions.loaded = true;
              }
            })
          );
        }
      },
    },
  };
  return buildField(defaults, args);
};

export const REPEAT = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'repeat',
    wrappers: ['multi-form-field'],
  };

  return buildField(defaults, args);
};

export const REPEAT_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'repeat-v2',
    wrappers: ['multi-wrapper-v2'],
  };

  return buildField(defaults, args);
};

export const FILE_FIELD = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'file',
    templateOptions: {
      showCopyToClipboard: false,
    },
    wrappers: ['form-field'],
  };
  return buildField(defaults, args);
};

export const CHECKBOX_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'checkbox-field-v2',
    wrappers: ['checkbox-v2-wrapper'],
  };

  return buildField(defaults, args);
};

export const MULTI_CHECKBOX_V2 = (args: RdrFormlyFieldConfig2) => {
  const defaults: RdrFormlyFieldConfig2 = {
    type: 'multicheckbox-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const TEXT = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'text',
    wrappers: ['form-field'],
  };

  return buildField(defaults, args);
};

export const TEXT_AREA = (args: RdrFormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'text-area',
    wrappers: ['form-field'],
    templateOptions: {
      wideField: false,
    },
    modelOptions: {
      updateOn: 'blur',
    },
  };

  return buildField(defaults, args);
};

export const SEARCH_INPUT = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'search-input',
  };

  return buildField(defaults, args);
};

export const TEXTAREA_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'textarea-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const INPUT_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'input-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const DATETIME_V3 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'datetime-v3',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const DATE_V3 = (args: RdrDateFormlyFieldConfig) => {
  const defaults: RdrDateFormlyFieldConfig = {
    type: 'date-v3',
    templateOptions: {
      datepickerType: 'input',
    },
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const SELECT_V2 = (args: FormlyFieldConfig, optionsService: Observable<any>) => {
  const defaults: FormlyFieldConfig = {
    type: 'select-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      optionsService,
      placeholder: PLACEHOLDER,
    },
  };
  return buildField(defaults, args);
};

export const NUMERIC_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'numeric-v2',
    templateOptions: {
      placeholder: '0',
    },
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const CURRENCY_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'currency-v2',
    templateOptions: {
      placeholder: '0',
    },
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const AIRLINES_V2 = (
  args: FormlyFieldConfig,
  optionsService: Observable<DropdownItem[]>
) => {
  const defaults: FormlyFieldConfig = {
    type: 'airlines-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      optionsService,
      filterCallback: filterDDWithCode,
    },
  };

  return buildField(defaults, args);
};

export const MILES_ACCOUNT_V2 = (
  args: FormlyFieldConfig,
  optionsService: Observable<DropdownItem[]>
) => {
  const defaults: FormlyFieldConfig = {
    type: 'miles-account-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      optionsService,
    },
  };

  return buildField(defaults, args);
};

export const TYPEAHEAD_V2 = (
  args: FormlyFieldConfig,
  optionsService: Observable<DropdownItem[]> | DropdownItem[]
) => {
  const defaults: FormlyFieldConfig = {
    type: 'typeahead-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      optionsService,
    },
  };

  return buildField(defaults, args);
};

export const EMAIL_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'email-v2',
    wrappers: ['form-field-v2'],
    validators: {
      validation: ['email'],
    },
    templateOptions: {
      placeholder: 'carl322332@comcast.net',
    },
  };

  return buildField(defaults, args);
};

export const CALCULATED_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'calculated-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      placeholder: '0',
    },
  };
  return buildField(defaults, args);
};

export const RADIO_V2 = (args: RdrFormlyFieldConfig2, items: DropdownItem<unknown>[]) => {
  const defaults: RdrFormlyFieldConfig2 = {
    type: 'radio-v2',
    wrappers: ['form-field-v2'],
    templateOptions: {
      items,
    },
  };

  return buildField(defaults, args);
};

export const FILE_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'file-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const CARD_NUMBER_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'card-number-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const CARD_CVV_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'card-cvv-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const CARD_EXPIRATION_V2 = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'card-expiration-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const CHIPS_V2 = (
  args: RdrFormlyFieldConfig2,
  optionsService: Observable<DropdownItem[]>
) => {
  const defaults: RdrFormlyFieldConfig2 = {
    type: 'formly-chips-v2',
    templateOptions: {
      optionsService,
    },
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const COUNT_V2 = (args: CountV2FormlyFieldConfig) => {
  const defaults: CountV2FormlyFieldConfig = {
    type: 'count-v2',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const BOOKING_COST_REPEAT = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'booking-cost-repeat',
  };

  return buildField(defaults, args);
};

export const BOOKING_COST = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'booking-cost',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

export const RICH = (args: FormlyFieldConfig) => {
  const defaults: FormlyFieldConfig = {
    type: 'rich',
    wrappers: ['form-field-v2'],
  };

  return buildField(defaults, args);
};

function buildField(defaults: FormlyFieldConfig, options: FormlyFieldConfig) {
  const result: RdrFormlyFieldConfig = {};
  lodash.merge(result, globalFieldDefaults, defaults, options);
  result.dataId = result.id || `${result.key as string}`;

  return {
    ...result,
  };
}

export const THREAD_SELECT = (
  args: FormlyFieldConfig,
  optionsService: Observable<DropdownItem[]>
) => {
  const defaults: FormlyFieldConfig = {
    type: 'thread-select',
    wrappers: ['form-field-v2'],
    templateOptions: {
      optionsService,
    },
  };

  return buildField(defaults, args);
};
