<card class="card">
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <typography-v2 look="section-title-bold" dataId="client"
          >{{ customParameters.firstName }} {{ customParameters.lastName }}</typography-v2
        >
        <ng-container
          *ngIf="customParameters.status === CallPopupStatus.Disconnected; else callDetails"
        >
          <div class="d-flex v2-mt-2">
            <typography-v2 look="subtitle" dataId="call-ended">The call ended</typography-v2>
          </div>
        </ng-container>
      </div>
      <div>
        <img src="assets/svg/call-icon.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex v2-mt-3">
    <button-v2
      *ngIf="customParameters.status !== CallPopupStatus.Incoming"
      (click)="openLead()"
      look="full-width"
      class="d-flex w-100"
      type="call"
      data-id="open-lead"
      >Open Lead</button-v2
    >
    <button-v2
      *ngIf="customParameters.status === CallPopupStatus.Incoming"
      (click)="acceptCall()"
      look="full-width"
      class="d-flex w-100"
      type="call"
      data-id="connect-with-lead"
      >Connect with Lead</button-v2
    >
    <button-v2
      *ngIf="customParameters.status === CallPopupStatus.Disconnected"
      (click)="closePopup()"
      type="call"
      class="v2-ml-1"
      data-id="close-lead"
      >Close</button-v2
    >
    <button-v2
      *ngIf="customParameters.status !== CallPopupStatus.Disconnected"
      (click)="disconnectCall()"
      type="destructive"
      class="v2-ml-1"
      data-id="end-call"
      >End the call</button-v2
    >
  </div>
</card>

<ng-template #callDetails>
  <div class="d-flex v2-mt-2">
    <typography-v2 look="subtitle" dataId="departureAt"
      >{{ customParameters.departureAt | rdrDate: 'date_only' }}</typography-v2
    >
    <span class="v2-mx-2">&middot;</span>
    <typography-v2 look="subtitle" dataId="departure"
      >{{ customParameters.departure }}</typography-v2
    >
    <span class="v2-mx-2">&rarr;</span>
    <typography-v2 look="subtitle" dataId="arrival">{{ customParameters.arrival }}</typography-v2>
  </div>
</ng-template>
