import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type SwitchButtonState = {
  label: string;
  key: string;
};

@Component({
  selector: 'switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchButtonComponent {
  @Input() states: SwitchButtonState[];
  @Input() currentState: SwitchButtonState;
  @Output() stateSelect: EventEmitter<SwitchButtonState> = new EventEmitter<SwitchButtonState>();

  select(state: SwitchButtonState) {
    if (this.currentState !== state) {
      this.stateSelect.emit(state);
    }
  }
}
