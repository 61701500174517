<btn class="add-button" [look]="look" size="big" (btnClick)="toggleCollapsed($event)">
  <ng-content></ng-content>
  <rdr-svg-icon class="ms-3" *ngIf="!collapsed" key="chevron-top"></rdr-svg-icon>
  <rdr-svg-icon class="ms-3" *ngIf="collapsed" key="chevron-bottom"></rdr-svg-icon>
  <ul *ngIf="!collapsed" [ngClass]="{'is-wide': isWide}" class="dropdown">
    <dropdown-item
      *ngFor="let option of options; trackBy: trackByFn"
      [item]="option"
      (click)="optionSelected($event, option)"
    >
    </dropdown-item>
  </ul>
</btn>
