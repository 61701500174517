import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'info-badge',
  templateUrl: './info-badge.component.html',
  styleUrls: ['./info-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBadgeComponent {
  @Input() key: string;
}
