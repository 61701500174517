import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  RadarTableColumn,
  RadarTableRow,
  RadarTableRowClick,
  RadarTableSort,
  RadarTableState,
} from '../radar-table.types';
import { UserNode, UserType } from '@app/generated/graphql';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';

@Component({
  selector: 'radar-table-state',
  templateUrl: './radar-table-state.component.html',
  styleUrls: ['./radar-table-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableStateComponent implements OnChanges {
  @Input() columns: RadarTableColumn[];
  @Input() rows: Observable<RadarTableRow[]>;
  @Input() tableState: RadarTableState;
  @Input() searchPlaceholder: string;
  @Input() rowLink: string;
  @Input() withTeamMembersSelector: boolean;
  @Input() columnsFixed: boolean;

  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() paginate: EventEmitter<number> = new EventEmitter();
  @Output() selection: EventEmitter<RadarTableRow[]> = new EventEmitter();
  @Output() sorting: EventEmitter<RadarTableSort> = new EventEmitter();
  @Output() rowClick: EventEmitter<RadarTableRowClick> = new EventEmitter();
  @Output() teamMembersSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() allTeamMembers: EventEmitter<Set<UserNode>> = new EventEmitter();

  readonly roles = UserType;
  cachedRows: RadarTableRow[];

  constructor(private cognitoAuthService: CognitoAuthService) {}

  get isSalesLead() {
    return this.cognitoAuthService.isSalesTeamLead;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows && this.rows) {
      this.rows = this.rows.pipe(tap((rows) => (this.cachedRows = rows)));
    }
  }

  onSearch(search: string): void {
    this.search.emit(search);
  }

  onPaginate(offset: number): void {
    this.paginate.emit(offset);
  }

  onSelect(selectedRows: RadarTableRow[]): void {
    this.selection.emit(selectedRows);
  }

  onSort(sort: RadarTableSort): void {
    this.sorting.emit(sort);
  }

  onRowClick({ event, row }: RadarTableRowClick): void {
    this.rowClick.emit({ event, row });
  }
}
