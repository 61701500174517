import { AbstractControl } from '@angular/forms';
import { FrequentFlyerNumberInput } from '@app/generated/graphql';
import { isNil } from 'lodash';

export const frequentFlyerNumbersValidator = (control: AbstractControl) => {
  const { value } = control;

  if (!value || value.length === 0) {
    return null;
  }

  const hasInvalid = (value as FrequentFlyerNumberInput[]).find((number) => {
    if (!number) return null;

    const keys: (keyof FrequentFlyerNumberInput)[] = ['airlineId', 'number'];

    return keys.some((key: keyof FrequentFlyerNumberInput) => isBlank(number[key]));
  });

  if (hasInvalid) {
    return { 'frequent-flyer-numbers': true };
  }

  return null;
};

function isBlank(val: unknown) {
  return isNil(val) || val === '';
}
