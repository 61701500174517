import { Pipe, PipeTransform } from '@angular/core';
import { RadarTableColumn } from '../radar-table.types';

@Pipe({
  name: 'sizedColumns',
})
export class SizedColumnsPipe implements PipeTransform {
  transform(columns: RadarTableColumn[]): boolean {
    return columns.every((column) => column.width && column.width > 0);
  }
}
