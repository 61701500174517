import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'user-reassign-modal',
  templateUrl: './user-reassign-modal.component.html',
  styleUrls: ['./user-reassign-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserReassignModalComponent implements OnInit {
  message: string;
  onConfirm: Subject<void>;
  proceedCaption: string;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.onConfirm = new Subject();
  }

  confirm() {
    this.onConfirm.next();
    this.modalRef.hide();
  }

  decline() {
    this.modalRef.hide();
  }
}
