import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';

@Component({
  selector: 'collapsed-tooltip',
  templateUrl: './collapsed-tooltip.component.html',
  styleUrls: ['./collapsed-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsedTooltipComponent implements OnInit {
  collapsedTooltip: boolean;
  checkedPermanentCollapse: boolean;

  @Input() tooltipKey: string;

  ngOnInit(): void {
    const item = localStorage.getItem(this.tooltipKey);
    this.collapsedTooltip = Boolean(item);
    this.checkedPermanentCollapse = Boolean(item);
  }

  toggle(): void {
    this.collapsedTooltip = !this.collapsedTooltip;
  }

  checkPermanentCollape(): void {
    this.checkedPermanentCollapse = !this.checkedPermanentCollapse;

    if (this.checkedPermanentCollapse) {
      try {
        localStorage.setItem(this.tooltipKey, 'true');
      } catch (e) {
        console.log('localStorage.setItem error: ', e);
      }
    } else {
      localStorage.removeItem(this.tooltipKey);
    }
  }
}
