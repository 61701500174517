import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';

export enum RdrFeature {
  xmas = 'xmas',
  airline_classes_administrator = 'airline_classes_administrator',
  edit_miles_program_price = 'edit_miles_program_price',
  twilio_service = 'twilio_service',
  gp_split = 'gp_split_admin',
  order_correction_editor = 'order_correction_editor',
  hot_lead_polling = 'hot_lead_polling',
  extra_leg = 'extra_leg',
  portal_cc = 'portal_cc',
  portal_auth = 'portal_auth',
  portal_hidden_flights = 'portal_hidden_flights',
  portal_hidden_flights_v2 = 'portal_hidden_flights_v2',
  disable_old_change_requests = 'disable_old_change_requests',
}

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  constructor(private cognitoAuthService: CognitoAuthService) {}

  isFeatureEnabled(feature: RdrFeature): boolean {
    return this.cognitoAuthService.user.profile?.feFeatures?.includes(feature) || false;
  }
}
