<div class="d-flex flex-column">
  <div
    class="d-flex"
    (mousemove)="onMousemove($event)"
    (mouseup)="onMouseup()"
    [ngStyle]="{'cursor': resiziblePane ? 'ew-resize' : 'default'}"
  >
    <left-pane
      (toggleLeftPane)="togglePane('left')"
      [ngStyle]="{'width.px': (hasLeftPane && panels.left.showed) ? panels.left.currentWidth : 32}"
      [showedPane]="panels.left.showed"
    >
      <ng-content select="[left]"></ng-content>
      <div (mousedown)="onMousedown('left')" class="pane-border pane-border-left"></div>
    </left-pane>

    <article
      [ngStyle]="{
        'margin-left.px': (hasLeftPane && panels.left.showed) ? panels.left.currentWidth : hasLeftPane ? 32 : 0,
        'margin-right.px': (hasRightPane && panels.right.showed) ? panels.right.currentWidth : hasRightPane ? 32 : 0
      }"
      class="middle"
    >
      <ng-content select="[middle]"></ng-content>
    </article>

    <right-pane
      (toggleRightPane)="togglePane('right')"
      [ngStyle]="{'width.px':  (hasRightPane && panels.right.showed) ? panels.right.currentWidth : 32}"
      [showedPane]="panels.right.showed"
    >
      <ng-content select="[right]"></ng-content>
      <div (mousedown)="onMousedown('right')" class="pane-border pane-border-right"></div>
    </right-pane>
  </div>
</div>
