<span
  >Page: <span [attr.data-id]="'current-page'">{{ pagination.offset + 1 }}</span> of
  <span [attr.data-id]="'total-pages'"
    >{{ Math.ceil(pagination.totalCount / pagination.limit) }}</span
  ></span
>
<rdr-svg-icon
  (click)="(pagination.offset !== paginationFirstPage) && onPaginate(paginationFirstPage)"
  [ngClass]="{'disabled': pagination.offset === paginationFirstPage }"
  [attr.data-id]="'first-page'"
  color="var(--gray-800)"
  key="chevrons-left"
  class="icon"
></rdr-svg-icon>
<rdr-svg-icon
  (click)="(pagination.offset !== paginationFirstPage) && onPaginate(pagination.offset - 1)"
  [ngClass]="{'disabled': pagination.offset === paginationFirstPage }"
  [attr.data-id]="'prev-page'"
  color="var(--gray-800)"
  key="chevron-left"
  class="icon"
></rdr-svg-icon>
<rdr-svg-icon
  (click)="(pagination.offset !== Math.ceil(pagination.totalCount / pagination.limit) - 1) && onPaginate(pagination.offset + 1)"
  [ngClass]="{'disabled': pagination.offset === Math.ceil(pagination.totalCount / pagination.limit) - 1 }"
  [attr.data-id]="'next-page'"
  color="var(--gray-800)"
  key="chevron-right"
  class="icon"
></rdr-svg-icon>
<rdr-svg-icon
  (click)="(pagination.offset !== Math.ceil(pagination.totalCount / pagination.limit) - 1) && onPaginate(Math.ceil(pagination.totalCount / pagination.limit) - 1)"
  [ngClass]="{'disabled': pagination.offset === Math.ceil(pagination.totalCount / pagination.limit) - 1 }"
  [attr.data-id]="'last-page'"
  color="var(--gray-800)"
  key="chevrons-right"
  class="icon"
></rdr-svg-icon>
