import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_ID } from '@app/shared/constants';
import { DropdownItem } from '@app/ui/components/dropdown.model';

@Pipe({
  name: 'onlyContainsEmptyItem',
})
export class OnlyContainsEmptyItemPipe implements PipeTransform {
  transform(val: DropdownItem[] | null): boolean {
    if (!val) {
      return false;
    }

    return val.length === 1 && val[0].value === EMPTY_ID;
  }
}
