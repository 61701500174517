import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserType } from '@generated/graphql';
import { AuthUser } from '@auth/user.model';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';

// Mock local AWS Cognito - get values from the Cookie.
function getCognitoCookieEndsWith(namePattern: string): string {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name && name.endsWith(namePattern)) {
      return value;
    }
  }
  return '';
}

if ('COGNITO_MOCK' in environment && environment.COGNITO_MOCK) {
  Auth.currentSession = () =>
    Promise.resolve({
      getAccessToken: () =>
        new CognitoAccessToken({ AccessToken: getCognitoCookieEndsWith('accessToken') }),
      getIdToken: () => new CognitoIdToken({ IdToken: getCognitoCookieEndsWith('idToken') }),
      getRefreshToken: () =>
        new CognitoRefreshToken({ RefreshToken: getCognitoCookieEndsWith('refreshToken') }),
      isValid: () => true,
    });
}

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthService {
  public user: AuthUser = {
    id: '',
  };

  get currentUser() {
    return Auth.currentAuthenticatedUser();
  }

  // TODO: not clear what this is doing
  get userRoleKey(): UserRoleFilterKey {
    return this.isOnlySalesAgent
      ? UserRoleFilterKey.salesAgentId
      : UserRoleFilterKey.ticketingAgentId;
  }

  get isAdministrator() {
    return this.user.type === UserType.Administrator;
  }

  get isOnlySalesAgent() {
    return this.user.type === UserType.SalesAgent;
  }

  get isSales() {
    return this.isOnlySalesAgent || this.isSalesTeamLead;
  }

  get isSalesTeamLead() {
    return this.user.type === UserType.SalesTeamLead;
  }

  get isSearcher() {
    return this.user.type === UserType.Searcher;
  }

  get isTicketingAgent() {
    return this.user.type === UserType.TicketingAgent;
  }

  get isAccountant() {
    return this.user.type === UserType.Accounting;
  }

  get hasHistoryAccess() {
    return this.user.historyAccess;
  }

  get isSalesDirector() {
    return !!this.user.salesDirector;
  }

  get isSuperadmin() {
    return Boolean(this.user.superadmin || this.user.isSuperadminContext);
  }

  hasCognitoSession() {
    return from(this.getCurrentSession()).pipe(
      map((el) => {
        return !!el;
      })
    );
  }

  getCurrentSession() {
    return Auth.currentSession()
      .then((session) => {
        const token = session.getIdToken();
        this.user.jwt = token.getJwtToken();

        const idToken = session.getIdToken();
        this.user.email = idToken.payload.email as string;
        Sentry.setUser({ email: this.user.email });

        return session;
      })
      .catch((e) => {
        console.log(e, 'error');
        return this.logOut();
      });
  }

  async logOut(): Promise<void> {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  hasRole(role: UserType): boolean {
    return !!(this.user.id && this.user.type === role);
  }
}

export enum UserRoleFilterKey {
  salesAgentId = 'salesAgentId',
  ticketingAgentId = 'ticketingAgentId',
}
