import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { RdrFormlyFieldConfig } from '@app/formly/types/basefield/basefield.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'repeat-v2',
  templateUrl: './repeat-v2.component.html',
  styleUrls: ['./repeat-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatV2Component extends FieldArrayType<RdrFormlyFieldConfig> implements OnInit {
  fieldWithError: RdrFormlyFieldConfig | null;

  ngOnInit() {
    if (this.field.fieldGroup?.length === 0 && !this.field.templateOptions?.startWithEmpty) {
      this.add();
    }

    this.formControl.valueChanges.pipe(UntilDestroyed(this)).subscribe(() => {
      this.fieldWithError = null;

      this.formControl.controls.forEach((control, i) => {
        if (control.dirty && control.errors && this.field.fieldGroup) {
          this.fieldWithError = this.field.fieldGroup[i];
        }
      });
    });
  }
}
