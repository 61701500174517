import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { EditableModule } from '@ngneat/edit-in-place';
import { FormlyInputWrapperComponent } from './wrappers/input-wrapper/formly-input-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldInputComponent } from '@app/formly/types/text-input/formly-field-input.component';
import { RadarFormlyFormComponent } from './components/radar-formly-form/radar-formly-form.component';
import { UiModule } from '@ui/ui.module';
import { FormlySelectComponent } from './types/select/formly-select.component';
import { FormlyRepeatComponent } from '@app/formly/types/repeat/repeat.component';
import { MultiWrapperComponent } from './wrappers/multi-wrapper/multi-wrapper.component';
import { FormlyDateComponent } from './types/date/formly-date.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormlyTypeaheadComponent } from './types/typeahead/typeahead.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SharedModule } from '@shared/shared.module';
import { FileComponent } from './types/file/file.component';
import { CurrencyComponent } from './types/currency/currency.component';
import { NumericComponent } from './types/numeric/numeric.component';
import { IMaskModule } from 'angular-imask';
import { TelInputComponent } from '@app/formly/types/tel-input/tel-input.component';
import { CheckboxStandaloneWrapperComponent } from './wrappers/checkbox-standalone-wrapper/checkbox-standalone-wrapper.component';
import { FormlyTextComponent } from './types/text/text.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormlyTextAreaComponent } from './types/textarea/text-area.component';
import { ValidationMessageComponent } from '@app/formly/components/validation-message/validation-message.component';
import { phoneValidator } from '@app/formly/validators/phone-validator';
import { emailValidator } from '@app/formly/validators/email-validator';
import { TypeaheadValueViewComponent } from './types/typeahead/typeahead-value-view/typeahead-value-view.component';
import { NumericValueViewComponent } from './types/numeric/numeric-value-view/numeric-value-view.component';
import { CurrencyValueViewComponent } from './types/currency/currency-value-view/currency-value-view.component';
import { TextInputValueViewComponent } from './types/text-input/text-input-value-view/text-input-value-view.component';
import { FieldLayoutComponent } from './types/field-layout/field-layout.component';
import { DateValueViewComponent } from './types/date/date-value-view/date-value-view.component';
import { PhoneValueViewComponent } from './types/tel-input/phone-value-view/phone-value-view.component';
import { SearchInputComponent } from './types/search-input/search-input.component';
import { FilterFieldWrapperComponent } from './wrappers/filter-field-wrapper/filter-field-wrapper.component';
import { FormlyFieldFormattedValuePipe } from './pipes/formly-field-formatted-value.pipe';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { RdrDatePipe } from '@ui/pipes/rdr-date.pipe';
import { RdrCurrencyPipe } from '@ui/pipes/rdr-currency.pipe';
import { nonzeroValidator } from './validators/nonzero-validator';
import { PipesModule } from '@ui/pipes/pipes.module';
import { FieldWithLinkWrapperComponent } from './wrappers/field-with-link-wrapper/field-with-link-wrapper.component';
import { DateRangeComponent } from './types/date-range/date-range.component';
import { DateRangeValueViewComponent } from './types/date-range/date-range-value-view/date-range-value-view.component';
import { alphanumericValidator } from '@app/formly/validators/alphanumeric-validator';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { TextareaV2Component } from './types/v2/textarea-v2/textarea-v2.component';
import { FormFieldV2Component } from './wrappers/form-field-v2/form-field-v2.component';
import { InputV2Component } from './types/v2/input-v2/input-v2.component';
import { SelectV2Component } from './types/v2/select-v2/select-v2.component';
import { CurrencyV2Component } from './types/v2/currency-v2/currency-v2.component';
import { AirlinesV2Component } from './types/v2/airlines-v2/airlines-v2.component';
import { NumericV2Component } from './types/v2/numeric-v2/numeric-v2.component';
import { FormlyTypeaheadV2Component } from './types/v2/typeahead-v2/formly-typeahead-v2.component';
import { CalculatedV2Component } from './types/v2/calculated-v2/calculated-v2.component';
import { CheckboxFieldV2Component } from './types/v2/checkbox-v2/checkbox-v2.component';
import { CheckboxV2WrapperComponent } from './wrappers/checkbox-v2-wrapper/checkbox-v2-wrapper.component';
import { greaterThenZeroValidator } from '@app/formly/validators/greater-then-zero-validator';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RepeatV2Component } from './types/v2/repeat-v2/repeat-v2.component';
import { PhoneV2Component } from './types/v2/phone-v2/phone-v2.component';
import { MultiWrapperV2Component } from './wrappers/multi-wrapper-v2/multi-wrapper-v2.component';
import { EmailV2Component } from './types/v2/email-v2/email-v2.component';
import { RadioV2Component } from './types/v2/radio-v2/radio-v2.component';
import { MulticheckboxV2Component } from './types/v2/multicheckbox-v2/multicheckbox-v2.component';
import { DatetimeV3Component } from './types/v3/datetime-v3/datetime-v3.component';
import { FileV2Component } from './types/v2/file-v2/file-v2.component';
import { CardNumberV2Component } from './types/v2/card-number-v2/card-number-v2.component';
import { CardExpirationV2Component } from './types/v2/card-expiration-v2/card-expiration-v2.component';
import { CardCvvV2Component } from './types/v2/card-cvv-v2/card-cvv-v2.component';
import { MilesAccountV2Component } from './types/v2/miles-account-v2/miles-account-v2.component';
import { ExtractTypeAheadMatchPipe } from './pipes/extract-typeahead-match.pipe';
import { OnlyContainsEmptyItemPipe } from './pipes/only-contains-empty-item.pipe';
import { IconsModule } from '@app/icons/icons.module';
import { onlyDateInPastValidator } from './validators/only-date-in-past.validator';
import { onlyDateInFutureValidator } from './validators/only-date-in-future.validator';
import { FormlyChipsV2Component } from './types/v2/chips-v2/formly-chips-v2.component';
import { CountV2Component } from './types/v2/count-v2/count-v2.component';
import { DateV3Component } from './types/v3/date-v3/date-v3.component';
import { BookingCostRepeatComponent } from './types/v2/booking-cost-repeat/booking-cost-repeat.component';
import { BookingCostComponent } from './types/v2/booking-cost/booking-cost.component';
import { bookingCostValidator } from './validators/booking-cost.validator';
import { hotelBookingCostValidator } from './validators/hotel-booking-cost-validator';
import { filtersTypes, filtersWrappers } from '@app/pages/search/filters/filters.config';
import { RichComponent } from './types/v2/rich/rich.component';
import { NgxEditorModule } from 'ngx-editor';
import { ThreadSelectComponent } from './types/v2/thread-select/thread-select.component';
import { frequentFlyerNumbersValidator } from './validators/frequent-flyer-numbers-validator';

@NgModule({
  declarations: [
    FormlyInputWrapperComponent,
    FormlyFieldInputComponent,
    RadarFormlyFormComponent,
    FormlySelectComponent,
    FormlyRepeatComponent,
    MultiWrapperComponent,
    FormlyDateComponent,
    FormlyTypeaheadComponent,
    FileComponent,
    CurrencyComponent,
    NumericComponent,
    TelInputComponent,
    CheckboxStandaloneWrapperComponent,
    FormlyTextComponent,
    FormlyTextAreaComponent,
    ValidationMessageComponent,
    TypeaheadValueViewComponent,
    NumericValueViewComponent,
    CurrencyValueViewComponent,
    TextInputValueViewComponent,
    FieldLayoutComponent,
    DateValueViewComponent,
    PhoneValueViewComponent,
    SearchInputComponent,
    FilterFieldWrapperComponent,
    FormlyFieldFormattedValuePipe,
    CopyToClipboardComponent,
    FieldWithLinkWrapperComponent,
    DateRangeComponent,
    DateRangeValueViewComponent,
    TextareaV2Component,
    FormFieldV2Component,
    InputV2Component,
    CurrencyV2Component,
    AirlinesV2Component,
    SelectV2Component,
    NumericV2Component,
    FormlyTypeaheadV2Component,
    CalculatedV2Component,
    CheckboxFieldV2Component,
    CheckboxV2WrapperComponent,
    RepeatV2Component,
    PhoneV2Component,
    MultiWrapperV2Component,
    EmailV2Component,
    RadioV2Component,
    MulticheckboxV2Component,
    DatetimeV3Component,
    FileV2Component,
    CardNumberV2Component,
    CardExpirationV2Component,
    CardCvvV2Component,
    MilesAccountV2Component,
    ExtractTypeAheadMatchPipe,
    OnlyContainsEmptyItemPipe,
    FormlyChipsV2Component,
    CountV2Component,
    DateV3Component,
    BookingCostRepeatComponent,
    BookingCostComponent,
    RichComponent,
    ThreadSelectComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditableModule,
    FormsModule,
    BsDatepickerModule,
    TypeaheadModule,
    ModalModule,
    CreditCardDirectivesModule,
    IMaskModule,
    SharedModule,
    TimepickerModule,
    PopoverModule,
    PipesModule,
    IconsModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: 'form-field',
          component: FormlyInputWrapperComponent,
        },
        {
          name: 'multi-form-field',
          component: MultiWrapperComponent,
        },
        {
          name: 'multi-wrapper-v2',
          component: MultiWrapperV2Component,
        },
        {
          name: 'checkbox-standalone',
          component: CheckboxStandaloneWrapperComponent,
        },
        {
          name: 'filter-field',
          component: FilterFieldWrapperComponent,
        },
        {
          name: 'field-with-link-wrapper',
          component: FieldWithLinkWrapperComponent,
        },
        {
          name: 'form-field-v2',
          component: FormFieldV2Component,
        },
        {
          name: 'checkbox-v2-wrapper',
          component: CheckboxV2WrapperComponent,
        },
        ...filtersWrappers,
      ],
      types: [
        {
          name: 'text-input',
          component: FormlyFieldInputComponent,
        },
        {
          name: 'numeric',
          component: NumericComponent,
        },
        {
          name: 'currency',
          component: CurrencyComponent,
        },
        {
          name: 'select',
          component: FormlySelectComponent,
        },
        {
          name: 'repeat',
          component: FormlyRepeatComponent,
        },
        {
          name: 'phone',
          component: TelInputComponent,
        },
        {
          name: 'date',
          component: FormlyDateComponent,
        },
        {
          name: 'date-range',
          component: DateRangeComponent,
        },
        {
          name: 'typeahead',
          component: FormlyTypeaheadComponent,
        },
        {
          name: 'file',
          component: FileComponent,
        },
        {
          name: 'text',
          component: FormlyTextComponent,
        },
        {
          name: 'text-area',
          component: FormlyTextAreaComponent,
        },
        {
          name: 'search-input',
          component: SearchInputComponent,
        },
        {
          name: 'textarea-v2',
          component: TextareaV2Component,
        },
        {
          name: 'input-v2',
          component: InputV2Component,
        },
        {
          name: 'datetime-v3',
          component: DatetimeV3Component,
        },
        {
          name: 'currency-v2',
          component: CurrencyV2Component,
        },
        {
          name: 'airlines-v2',
          component: AirlinesV2Component,
        },
        {
          name: 'miles-account-v2',
          component: MilesAccountV2Component,
        },
        {
          name: 'typeahead-v2',
          component: FormlyTypeaheadV2Component,
        },
        {
          name: 'select-v2',
          component: SelectV2Component,
        },
        {
          name: 'numeric-v2',
          component: NumericV2Component,
        },
        {
          name: 'date-v3',
          component: DateV3Component,
        },
        {
          name: 'calculated-v2',
          component: CalculatedV2Component,
        },
        {
          name: 'checkbox-field-v2',
          component: CheckboxFieldV2Component,
        },
        {
          name: 'radio-v2',
          component: RadioV2Component,
        },
        {
          name: 'phone-v2',
          component: PhoneV2Component,
        },
        {
          name: 'repeat-v2',
          component: RepeatV2Component,
        },
        {
          name: 'email-v2',
          component: EmailV2Component,
        },
        {
          name: 'multicheckbox-v2',
          component: MulticheckboxV2Component,
        },
        {
          name: 'file-v2',
          component: FileV2Component,
        },
        {
          name: 'card-number-v2',
          component: CardNumberV2Component,
        },
        {
          name: 'card-cvv-v2',
          component: CardCvvV2Component,
        },
        {
          name: 'card-expiration-v2',
          component: CardExpirationV2Component,
        },
        {
          name: 'formly-chips-v2',
          component: FormlyChipsV2Component,
        },
        {
          name: 'count-v2',
          component: CountV2Component,
        },
        {
          name: 'booking-cost-repeat',
          component: BookingCostRepeatComponent,
        },
        {
          name: 'booking-cost',
          component: BookingCostComponent,
        },
        {
          name: 'rich',
          component: RichComponent,
        },
        {
          name: 'thread-select',
          component: ThreadSelectComponent,
        },
        ...filtersTypes,
      ],
      validators: [
        { name: 'phone', validation: phoneValidator },
        { name: 'email', validation: emailValidator },
        { name: 'nonzero', validation: nonzeroValidator },
        { name: 'alphanumeric', validation: alphanumericValidator },
        { name: 'greater-then-zero', validation: greaterThenZeroValidator },
        { name: 'only-date-in-past', validation: onlyDateInPastValidator },
        { name: 'only-date-in-future', validation: onlyDateInFutureValidator },
        { name: 'booking-cost', validation: bookingCostValidator },
        { name: 'hotel-booking-cost', validation: hotelBookingCostValidator },
        { name: 'frequent-flyer-numbers', validation: frequentFlyerNumbersValidator },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'phone', message: 'Invalid phone number' },
        { name: 'email', message: 'Invalid email address' },
        { name: 'nonzero', message: `This field can't be zero` },
        { name: 'alphanumeric', message: `Only letters and numbers are accepted` },
        { name: 'greater-then-zero', message: `Value must be greater then zero` },
        { name: 'invalidDate', message: `Invalid Date` },
        { name: 'invalidTime', message: `Invalid Time` },
        { name: 'onlyInThePast', message: `Date should be in the past` },
        { name: 'onlyInTheFuture', message: `Date should be in the future` },
        { name: 'booking-cost', message: `All the cost fields are required` },
      ],
    }),
    UiModule,
    UiV2Module,
    FormlySelectModule,
    TooltipModule,
    NgxEditorModule,
  ],
  exports: [
    FormlyModule,
    FormlyFieldInputComponent,
    RadarFormlyFormComponent,
    FormlyFieldFormattedValuePipe,
    CopyToClipboardComponent,
    FormlyTextComponent,
    AirlinesV2Component,
    RadioV2Component,
  ],
  providers: [RdrDatePipe, RdrCurrencyPipe],
})
// TODO: https://medium.com/hackernoon/organize-your-forms-application-using-ngx-fomrly-796150461045
export class RadarFormlyModule {}
