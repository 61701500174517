import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import {
  CertificateDocumentPdfGQL,
  CertificateDocumentPdfQueryVariables,
  ChangeOrderTicketingAgentV2GQL,
  CreateFileOrderSignatureV2GQL,
  CreateFileOrderSignatureV2MutationVariables,
  DeleteFileOrderSignatureV2GQL,
  DeleteFileOrderSignatureV2MutationVariables,
  DeleteOrderPaymentGQL,
  DeleteOrderPaymentMutationVariables,
  GetExistingPassengersGQL,
  GetExistingPassengersQueryVariables,
  GetOrderV2GQL,
  GetPaymentSuppliersGQL,
  GetPresignedUrlGQL,
  GetPresignedUrlQueryVariables,
  InvoicePdfGQL,
  InvoicePdfQueryVariables,
  OrderConfirmationPdfV2GQL,
  OrderConfirmationPdfV2QueryVariables,
  OrderCorrectionReasonNode,
  OrderCorrectionReasonsGQL,
  OrderHistoryGQL,
  OrderHistoryQueryVariables,
  OrderNode,
  PaymentAuthorizationTemplateGQL,
  PaymentAuthorizationTemplateQueryVariables,
  ReassignTicketingAgent,
  SalesPdfGQL,
  SalesPdfQueryVariables,
  SaveOrderPassengerNewGQL,
  SaveOrderPassengerNewMutationVariables,
  SendOrderConfirmationEmailGQL,
  SendOrderConfirmationEmailMutationVariables,
  UpdateFlightSegmentOrderGQL,
  UpdateFlightSegmentOrderMutationVariables,
  UpdateOrderNewGQL,
  UpdateOrderNewMutationVariables,
  UpsertOrderPaymentGQL,
  UpsertOrderPaymentMutationVariables,
} from '@app/generated/graphql';
import { GenericNode, GraphqlID, RefetchQueries } from '@app/shared/types';
import { mapEntityToDropdownItem } from '@app/shared/utils/dropdown-utils';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersPageV2Service {
  protected order$$ = new BehaviorSubject<OrderNode | null>(null);

  constructor(
    private getOrderV2GQL: GetOrderV2GQL,
    private sendOrderConfirmationEmailGQL: SendOrderConfirmationEmailGQL,
    private orderConfirmationPdfV2GQL: OrderConfirmationPdfV2GQL,
    private createFileOrderSignatureV2GQL: CreateFileOrderSignatureV2GQL,
    private deleteFileOrderSignatureV2GQL: DeleteFileOrderSignatureV2GQL,
    private getPresignedUrlGQL: GetPresignedUrlGQL,
    private updateOrderNewGQL: UpdateOrderNewGQL,
    private getExistingPassengersGQL: GetExistingPassengersGQL,
    private getPaymentSuppliersGQL: GetPaymentSuppliersGQL,
    private paymentAuthorizationTemplateGQL: PaymentAuthorizationTemplateGQL,
    private upsertOrderPaymentGQL: UpsertOrderPaymentGQL,
    private deleteOrderPaymentGQL: DeleteOrderPaymentGQL,
    private reassignOrderAgentGQL: ChangeOrderTicketingAgentV2GQL,
    private orderHistoryGQL: OrderHistoryGQL,
    private orderCorrectionReasonsGQL: OrderCorrectionReasonsGQL,
    private salesPdfGQL: SalesPdfGQL,
    private invoicePdfGQL: InvoicePdfGQL,
    private cognitoAuthService: CognitoAuthService,
    private updateFlightSegmentOrderGQL: UpdateFlightSegmentOrderGQL,
    private saveOrderPassengerNewGQL: SaveOrderPassengerNewGQL,
    private certificateDocumentPdfGQL: CertificateDocumentPdfGQL
  ) {}

  get order$() {
    return this.order$$.asObservable();
  }

  setOrder(order: OrderNode | null) {
    this.order$$.next(order);
  }

  getOrder(id: GraphqlID) {
    const withInvoices = this.cognitoAuthService.isAccountant;

    return this.getOrderV2GQL
      .fetch({ id, withInvoices })
      .subscribe(({ data }) => this.order$$.next(data.order as OrderNode));
  }

  sendConfirmationEmail(params: SendOrderConfirmationEmailMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.sendOrderConfirmationEmailGQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.sendNotification?.result?.order as OrderNode);
      })
    );
  }

  getConfirmationEmailPdf(params: OrderConfirmationPdfV2QueryVariables) {
    return this.orderConfirmationPdfV2GQL.fetch(params);
  }

  createFileOrderSignature(params: CreateFileOrderSignatureV2MutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.createFileOrderSignatureV2GQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.createFileOrderSignature?.order as OrderNode);
      })
    );
  }

  reassignOrderAgent(input: ReassignTicketingAgent, refetchQueries?: RefetchQueries) {
    const withInvoices = this.cognitoAuthService.isAccountant;

    return this.reassignOrderAgentGQL.mutate({ input, withInvoices }, { refetchQueries });
  }

  deleteFileOrderSignature(params: DeleteFileOrderSignatureV2MutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.deleteFileOrderSignatureV2GQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.deleteFileOrderSignature?.order as OrderNode);
      })
    );
  }

  getPresignedUrl(params: GetPresignedUrlQueryVariables) {
    return this.getPresignedUrlGQL.fetch(params);
  }

  updateOrder(
    params: UpdateOrderNewMutationVariables,
    refetchQueries?: RefetchQueries
  ): Observable<any> {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.updateOrderNewGQL.mutate(params, { refetchQueries }).pipe(
      tap(({ data }) => {
        this.setOrder(data?.updateOrderV2?.result as OrderNode);
      })
    );
  }

  getExistingPassengers(params: GetExistingPassengersQueryVariables): Observable<DropdownItem[]> {
    return this.getExistingPassengersGQL.fetch(params).pipe(
      map(({ data }) => {
        const existingPassengersDDItems = data.client?.passengers?.edges?.map(({ node }) => {
          return mapEntityToDropdownItem(node as GenericNode, 'fullName', 'id');
        });

        return existingPassengersDDItems || [];
      })
    );
  }

  saveOrderPassengerNew(params: SaveOrderPassengerNewMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.saveOrderPassengerNewGQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.updatePassengerPassportV2?.result?.order as OrderNode);
      })
    );
  }

  getPaymentSuppliers(): Observable<DropdownItem[]> {
    return this.getPaymentSuppliersGQL.fetch().pipe(
      map(({ data }) => {
        const paymentSuppliers =
          data.paymentSuppliers?.edges.map(({ node }) =>
            mapEntityToDropdownItem(node as GenericNode)
          ) || [];

        return [EMPTY_DROP_DOWN_ITEM, ...paymentSuppliers];
      })
    );
  }

  paymentAuthorizationTemplate(params: PaymentAuthorizationTemplateQueryVariables) {
    return this.paymentAuthorizationTemplateGQL.fetch(params);
  }

  upsertOrderPayment(params: UpsertOrderPaymentMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.upsertOrderPaymentGQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.upsertOrderPayment?.result as OrderNode);
      })
    );
  }

  deleteOrderPayment(params: DeleteOrderPaymentMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.deleteOrderPaymentGQL.mutate(params).pipe(
      tap(({ data }) => {
        this.setOrder(data?.deleteOrderPayment?.result as OrderNode);
      })
    );
  }

  getOrderHistory(params: OrderHistoryQueryVariables) {
    return this.orderHistoryGQL
      .fetch(params)
      .pipe(map(({ data }) => (data.orderHistory || []) as OrderNode[]));
  }

  getOrderCorrectionReasons(): Observable<OrderCorrectionReasonNode[]> {
    return this.orderCorrectionReasonsGQL.fetch(undefined, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => {
        const reasons = data.orderCorrectionReasons?.edges.map(
          ({ node }) => node as OrderCorrectionReasonNode
        );
        return reasons || [];
      })
    );
  }

  downloadInvoice(params: InvoicePdfQueryVariables) {
    return this.invoicePdfGQL.fetch(params);
  }

  downloadSalesReceipt(params: SalesPdfQueryVariables) {
    return this.salesPdfGQL.fetch(params);
  }

  updateFlightSegmentOrder(params: UpdateFlightSegmentOrderMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.updateFlightSegmentOrderGQL
      .mutate(params)
      .subscribe(({ data }) =>
        this.setOrder(data?.updateFlightSegment?.result?.booking?.order as OrderNode)
      );
  }

  certificateDocumentPdf(params: CertificateDocumentPdfQueryVariables) {
    return this.certificateDocumentPdfGQL.fetch(params);
  }
}
