import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.scss'],
})
export class LeftPaneComponent {
  @Input() showedPane = true;
  @Output() toggleLeftPane = new EventEmitter();
}
