<div
  [ngClass]="{ 'cursor-pointer': editable && !locked }"
  (click)="editable && editableCmp?.displayEditMode()"
>
  <div class="d-flex align-items-center justify-content-between mb-1">
    <span>{{ title }}</span>
    <ng-container *ngIf="editable">
      <rdr-svg-icon *ngIf="!locked" class="icon" key="pencil-create"></rdr-svg-icon>
      <rdr-svg-icon class="icon" *ngIf="locked" key="lock"></rdr-svg-icon>
    </ng-container>
  </div>
  <span
    *ngIf="!editable || locked; else editableTmpl"
    [class.dangerously]="dangerously"
    class="value"
  >
    <ng-container *ngIf="asNumber; else default">
      <typography data-id="amount" look="H4" class="me-1"
        >{{ formControl?.value || 0 | number }}</typography
      >
    </ng-container>
    <ng-template #default>
      <typography data-id="amount" look="H4" class="me-1"
        >{{ formControl?.value || 0 | rdrCurrency: withCents ? 'cents': 'default' }}</typography
      >
    </ng-template>
    <typography *ngIf="isNumber(item?.fees)" look="details" class="fees"
      >Fees:&nbsp;<span data-id="fees">{{ item?.fees | rdrCurrency }}</span></typography
    >
  </span>
  <ng-template #editableTmpl>
    <editable data-id="amount" (save)="update()" (cancel)="update()" #editableCmp>
      <ng-template viewMode>
        <span class="value" [class.dangerously]="dangerously">
          <typography look="H4">{{ formControl?.value || 0 | rdrCurrency }}</typography>
        </span>
      </ng-template>
      <ng-template editMode>
        <input
          type="number"
          class="formly-input"
          [formControl]="formControl"
          editableOnEnter
          editableOnEscape
          editableFocusable
        />
      </ng-template>
    </editable>
  </ng-template>
</div>
