import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusType } from '@app/generated/graphql';
import { ALWAYS_EXCLUDED_ORDER_STATUSES } from '@app/shared/constants';

@Pipe({
  name: 'orderStatusIsOption',
})
export class OrderStatusIsOptionPipe implements PipeTransform {
  transform(orderStatus: OrderStatusType | undefined) {
    if (!orderStatus) {
      return false;
    }
    const odredOptionStatuses = [...ALWAYS_EXCLUDED_ORDER_STATUSES];
    return odredOptionStatuses.includes(orderStatus);
  }
}
