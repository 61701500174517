import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientNode } from '@generated/graphql';

@Component({
  selector: 'client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCardComponent {
  @Input() client: Partial<Pick<ClientNode, 'id' | 'lastName' | 'firstName' | 'balance'>>;
}
