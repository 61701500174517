<div class="modal-header">Conflicting Changes</div>

<div class="modal-body">
  <typography look="H3" class="mb-4">{{ message }} </typography>

  <div class="d-flex">
    <btn look="secondary" size="big" class="me-3" (btnClick)="confirm()">
      {{ proceedCaption }}
    </btn>

    <btn look="primary" size="big" (btnClick)="decline()">Cancel</btn>
  </div>
</div>
