<input
  (keyup.enter)="onSearch($event)"
  [value]="searchValue"
  [attr.data-id]="'search'"
  placeholder="{{ searchPlaceholder }}"
  class="search"
/>

<rdr-svg-icon
  *ngIf="searchValue; else searchIcon"
  (click)="onClear()"
  class="icon"
  color="var(--gray-600)"
  key="circle-x"
></rdr-svg-icon>

<ng-template #searchIcon>
  <rdr-svg-icon class="icon" color="var(--gray-600)" key="search"></rdr-svg-icon>
</ng-template>
