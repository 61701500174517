<div class="collapsed-tooltip d-flex flex-column">
  <div class="d-flex justify-content-between align-items-center cursor-pointer" (click)="toggle()">
    <span class="question">
      <rdr-svg-icon key="question-circle" fontSize="16px" color="var(--placeholder)"></rdr-svg-icon>
    </span>
    <ng-content select="[header]"></ng-content>

    <span class="toggle"
      ><rdr-svg-icon
        [key]="collapsedTooltip ? 'chevron-bottom' : 'chevron-top'"
        fontSize="14px"
        color="var(--placeholder)"
      ></rdr-svg-icon
    ></span>
  </div>
  <div *ngIf="!collapsedTooltip" class="mt-1">
    <ng-content select="[content]"></ng-content>

    <label for="input" class="d-flex align-items-center cursor-pointer">
      <input
        id="input"
        type="checkbox"
        class="checkbox cursor-pointer"
        [checked]="checkedPermanentCollapse"
        (change)="checkPermanentCollape()"
      />
      <span class="ms-1">Don’t show again</span>
    </label>
  </div>
</div>
