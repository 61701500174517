import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItem } from '@ui/components/dropdown.model';
import { DropdownAbstractClass } from '@ui/components/dropdown-abstract';
import { ButtonLook } from '@ui/components/button/btn.component';

@Component({
  selector: 'option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionButtonComponent extends DropdownAbstractClass {
  @Input() options: DropdownItem[];
  @Input() look: ButtonLook = 'primary';
  @Input() isWide = false;
  @Output() optionClicked: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  optionSelected(event: MouseEvent, item: DropdownItem) {
    event.stopPropagation();
    this.optionClicked.emit(item);
    this.collapsed = !this.collapsed;
  }
}
